<template>
  <div class="cardBreak">
    <h6 class="cardBreak__title">Receso</h6>
    <StopWatch />
  </div>
</template>

<script>
import StopWatch from "./StopWatch.vue";
export default {
  components: {
    StopWatch,
  },
};
</script>

<style lang="scss">
.cardBreak {
  margin-top: $mpadding;
  padding: $mpadding 40px $mpadding * 2 40px;
  box-shadow: $dshadow;
  border-radius: $mradius;
  background-color: $white;
  &__title {
    font-size: $title-mobile;
    color: $primary;
  }
}
</style>
