<template>
  <div class="clock">
    <span class="clock__time">{{ time }}</span>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { socket } from "@/socket";
  export default {
    data() {
      return {
        time: "00:00:00",
        timeBegan: localStorage.getItem("timeBegan") || null,
        started: null,
        running: false,
        typeBreak: null,
      };
    },
    created() {
      this.timeBegan = localStorage.getItem("timeBegan");
      this.running = localStorage.getItem("running");
      if (this.running) {
        this.start();
      }
    },
    mounted() {
      this.$root.$on("runWatch", () => {
        this.start();
      });
      this.$root.$on("sendTime", this.sendTime);
    },
    beforeDestroy() {
      this.$root.$off("sendTime", this.sendTime);
    },
    methods: {
      ...mapGetters("user", { getProfile: "getProfile" }),
      stop() {
        localStorage.removeItem("running", this.running);
        localStorage.removeItem("timeBegan", this.timeBegan);
        this.running = false;
        clearInterval(this.started);
        this.timeBegan = null;
      },
      sendTime() {
        const user = this.$store.state.user.profile;
        const modal = document.getElementById("breakModal");
        modal.style.display = "none";
        this.stop();
        this.checkTypeBreak();
        socket.emit("sendTime", { modelId: user._id, time: this.time, typeBreak: this.typeBreak });
      },
      start() {
        if (this.timeBegan === null) {
          this.timeBegan = new Date();
          localStorage.setItem("running", true);
          localStorage.setItem("timeBegan", this.timeBegan);
        }
        this.started = setInterval(this.clockRunning, 1000);
        this.running = true;
      },
      clockRunning() {
        let currentTime = new Date(),
          startTime = new Date(this.timeBegan),
          timeElapsed = new Date(currentTime - startTime),
          hour = timeElapsed.getUTCHours(),
          min = timeElapsed.getUTCMinutes(),
          sec = timeElapsed.getUTCSeconds(),
          ms = timeElapsed.getUTCMilliseconds();

        this.time = this.zeroPrefix(hour, 2) + ":" + this.zeroPrefix(min, 2) + ":" + this.zeroPrefix(sec, 2);
      },

      zeroPrefix(num, digit) {
        let zero = "";
        for (let i = 0; i < digit; i++) {
          zero += "0";
        }
        return (zero + num).slice(-digit);
      },
      checkTypeBreak() {
        const radios = document.getElementsByName("break");
        for (const radio of radios) {
          if (radio.checked) {
            this.typeBreak = radio.value;
          }
        }
      },
    },
  };
</script>

<style lang="scss">
  .clock {
    display: flex;
    align-items: center;
    &__time {
      min-width: 70px;
      margin-top: $mpadding/2;
      font-size: 1.2rem;
    }
  }
</style>
