<script>
  export default {
    data() {
      return {
        isOpen: false,
      };
    },
    props: ["title", "items", "type"],
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      handleAction(option) {
        const { action, requireOpts } = option;
        if (typeof action === "function") {
          requireOpts ? action(option) : action();
        }
      },
    },
  };
</script>

<template>
  <div :class="`dropdown dropdown--${type}`">
    <div :class="`dropdown__header dropdown__header--${type}`" @click="toggleDropdown">
      <h3 class="dropdown__title">{{ title }}</h3>
      <button class="dropdown__collapseButton" type="button"><iconic :name="isOpen ? 'chevronUp' : 'chevronDown'" /></button>
    </div>

    <transition name="fade">
      <div
        v-if="isOpen"
        :class="[
          'dropdown__content',
          `dropdown__content--${type}`,
          {
            'dropdown__content--open': isOpen,
            [`dropdown__content--${type}--open`]: isOpen && type,
          },
        ]"
      >
        <ul class="dropdown__list">
          <li :class="`dropdown__listItem dropdown__listItem--${type} dropdown__listItem--${option.style}`" v-for="(option, idx) in items" :key="idx + option.name" @click="handleAction(option)">
            {{ option.name }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
  .dropdown {
    width: 280px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    position: absolute;
    top: 185px;

    &--accountView {
      position: static;
      justify-self: flex-end;
      width: 400px;
      background: #fff;
      top: 0px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 10px;
      width: 100%;

      &--accountView {
        padding: 7px;
      }
    }

    &__title {
      color: #212529;
      font-size: 18px;
      font-weight: 400;
    }

    &__collapseButton {
      display: flex;
      padding: $space-4 $space-2;
      border: none;
      background-color: transparent;
    }

    &__content {
      width: 100%;
      padding: 0 10px 10px;
      overflow: hidden;
      transition: height 0.8s ease;
      height: 0;

      &--accountView {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &__content--open {
      height: fit-content;
    }

    &__list {
      list-style-type: none;
      display: flex;
      justify-content: space-around;
    }

    &__listItem {
      cursor: pointer;
      text-transform: capitalize;
      white-space: nowrap;
      padding: 7px 10px;
      user-select: none;
      color: $white;
      background-color: $primary-color;
      border: none;
      border-radius: 5px;
      &:hover {
        opacity: $opacity-button;
      }
      &:active {
        transform: scale(0.97);
      }
      &--streamate {
        background-color: $matisse;
      }

      &--accountView {
        height: 39px;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: height 10ms ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    height: 0;
  }
</style>
