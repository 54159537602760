<template>
  <div class="userCardList">
    <div :class="['userCardList__containerTitle', title]" frsc v-if="hasHeader">
      <div class="userCardList__container">
        <h6 :class="['userCardList__title', isSubList ? 'titleSmall' : '', styleTitle ? 'app' : '']">{{ title }}</h6>
        <p class="userCardList__subtitle" v-if="subtitle">{{ subtitle }}</p>
      </div>
      <div class="userCardList__container" v-if="hasFinder">
        <Finder @onSearch="$emit('onSearch', $event)" />
      </div>
    </div>
    <ul :class="['userCardList__list', title, hasScroll ? 'hasScroll' : '']" v-show="users.length">
      <UserItem :class="['userCardList__item']" v-for="user in users" :key="user._id" :user="user" :length="isSubList ? null : user.countModels" />
      <div class="userCardList__more">
        <div class="spinner" v-if="pagination.isLoading"></div>
        <a
          :class="['userCardList__btnFetch', !hasScroll ? 'click-on-bottom' : '']"
          v-if="pagination.hasMore && !pagination.loadLikes"
          @click="getMoreUsers"
        >
          Ver más
        </a>
      </div>
    </ul>
    <p class="userCardList__nothing" fcenter v-if="!users.length && !pagination.isLoading">No hay usuarios para mostrar</p>
  </div>
</template>
<script>
export default {
  name: "UserCardList",
  components: {
    UserItem: () => import("./UserCardItem.vue"),
    Finder: () => import("@/components/widgets/Finder.vue"),
  },
  props: {
    title: { type: String, required: true },
    subtitle: { type: String },
    users: { type: Array, default: () => [] },
    isSubList: { type: Boolean, default: false },
    pagination: { type: Object, default: () => ({ hasMore: false, isLoading: false }) },
    hasScroll: { type: Boolean, default: false },
    hasFinder: { type: Boolean, default: false },
    styleTitle: { type: Boolean, default: false },
    hasHeader: { type: Boolean, default: true },
  },
  data: function() {
    return {
      models: {},
      page: 0,
    };
  },
  methods: {
    getMoreUsers() {
      this.$emit("getMoreUsers");
    },
    addScrollEvent() {
      let body;
      if (this.hasScroll) {
        body = document.querySelector(`.userCardList__list.${this.title}`);
        this.$global.clickOnBottom({
          el: body,
          onBottom: this.getMoreUsers,
          gap: 10,
        });
      }
    },
  },
  beforeMount() {},
  mounted() {
    this.addScrollEvent();
  },
};
</script>

<style lang="scss">
.userCardList {
  border: 1px solid $gray-edward;
  border-radius: $mradius;
  background: $white;
  .click-on-bottom {
    opacity: 1;
  }
  &__containerTitle {
    justify-content: space-between;
    padding: $mpadding/2 $mpadding/2;
    text-align: start;
    border-bottom: 2px solid $gray-edward;
    .finder__container {
      margin-right: 0;
    }
  }
  &__list {
    &.hasScroll {
      max-height: $tablet_width;
      overflow-y: auto;

      scrollbar-width: thin;
      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        height: 5px;
        background-color: $silver;
        border-radius: 4px;
      }
    }
  }
  &__title {
    font-size: 1.5rem;
    font-weight: lighter;
    &.app {
      font-weight: bold;
      font-size: $subtitle-mobile;
    }
    &.titleSmall {
      font-size: 18px;
    }
  }
  &__subtitle {
    font-size: 12px;
    font-weight: bold;
    color: $chicago;
  }
  &__nothing {
    padding: $mpadding/2;
    color: $button-disable;
    font-size: 1.1rem;
    font-weight: bold;
  }

  &__item {
    list-style: none;
    border-bottom: 0.5px solid $gray-edward;
    &:nth-last-child(2) {
      border-bottom: none;
    }
  }
  &__more {
    width: 100%;
    text-align: center;
  }
  &__btnFetch {
    cursor: pointer;
    &:hover {
      color: $lipstick;
      text-decoration: underline;
    }
  }
  @media screen and (min-width: $tablet_width) {
    &__title {
      &.app {
        font-size: $subtitle-desktop;
      }
    }
  }
}
</style>
