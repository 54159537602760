<template>
  <div class="userCard" v-bind="initSocketEvents">
    <UserList
      :styleTitle="true"
      title="Monitores"
      subtitle="Lista de monitores con sus respectivas modelos oficiales"
      :users="users"
      :pagination="pagination"
      :hasFinder="true"
      @getMoreUsers="getMoreUsers"
      @onSearch="onSearch"
    />
  </div>
</template>

<script>
  import UserList from "./UserCardList.vue";
  import { socket } from "@/socket";

  export default {
    components: { UserList },
    data: function() {
      return {
        users: [],
        search: "",
        page: 0,
        pagination: { hasMore: false, isLoading: false },
      };
    },
    computed: {
      initSocketEvents() {
        if (this.$socketState) {
          socket.on("userDisconnected", this.updateUser);
          socket.on("userUpdated", this.updateUser);
        }
      },
    },
    methods: {
      async getUsers() {
        this.pagination.isLoading = true;
        const { data } = await this.$axios.get(`/users?page=${this.page}&sort=user&account=monitor&limit=20&q=${this.search}`);
        this.pagination.hasMore = data.hasMore;
        this.pagination.isLoading = false;
        return data.users;
      },
      async getMoreUsers() {
        if (this.pagination.hasMore && !this.pagination.isLoading) {
          this.page++;
          const usersLocal = await this.getUsers();
          this.users.push(...usersLocal);
        }
      },
      async onSearch(search) {
        this.search = search;
        this.page = 0;
        this.users = await this.getUsers();
      },
      updateUser(info) {
        if (info.user) {
          const index = this.users.findIndex((user) => {
            return user._id === info._id;
          });
          if (index !== -1) {
            this.users.splice(index, 1, info);
            return;
          }
        }
        const index = this.users.findIndex((user) => {
          return user._id === info.user._id;
        });
        if (index !== -1) {
          this.users.splice(index, 1, info.user);
          return;
        }
      },
    },
    async beforeMount() {
      this.users = await this.getUsers();
    },
  };
</script>

<style lang="scss">
  .userCard {
    max-width: 1440px;
    margin: $mpadding 0;
    border-radius: $mradius;
    box-shadow: $dshadow;
  }
</style>
