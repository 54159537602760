<template>
  <section class="account-user" v-bind="runGoals">
    <div class="account-user__mask" v-if="showMask"></div>
    <div class="account-user__buttons-model" v-if="assignedMonitor">
      <div class="account-user__mainButtons">
        <template v-if="$userData.jasminUser">
          <button class="account-user__button-model" @click="() => openByOffice('modelcenter')">
            ¡Mira tus estadísticas!
          </button>
          <button class="account-user__button-model" @click="() => openByOffice('jasmin')">
            Jasmin
          </button>
        </template>
        <template v-if="getStreaming($userData.streamateUser)">
          <button class="account-user__button-model account-user__button-model--streamate" @click="() => openByOffice('streamate')">
            Streamate
          </button>
        </template>
      </div>
      <div class="account-user__backupButtons" v-if="userCanUseBot">
        <DropDownCollapse title="Reconexión de Respaldo" :items="this.backupButtons" :show="showDropdown" type="accountView" />
      </div>
    </div>
    <button class="account-user__button-model" @click="wantToBroadcast" v-if="showButtonToBroadcast">Quiero trasmitir</button>
    <BreakModel v-show="isModalActive" ref="modalBreak" />

    <div class="account-user__msgRoom" v-if="isPresencialModel && msgVerifyRoom">
      <iconic class="account-user__iconMsgRoom" name="reload" @click.native="verifyRoom" />
      <span v-html="msgVerifyRoom"></span>
    </div>

    <div class="account-user__container" v-if="isShow">
      <h3 class="account-user__title">INFORMACIÓN DE CUENTA</h3>

      <div class="account-user__row" v-if="$userData.role === 'model'">
        <div class="account-user__ico" v-if="!assignedMonitor">
          <i class="fas fa-user-shield"></i>
        </div>
        <Avatar class="account-user__monitorAvatar" :user="assignedMonitor" v-else />
        <p class="account-user__value">
          {{ `${assignedMonitor ? assignedMonitor.artisticName || assignedMonitor.user : "MONITOR NO ASIGNADO"}` }}
        </p>
      </div>

      <div class="account-user__row-wrapper" v-for="(user, id) in getUser" :key="id">
        <div class="account-user__row" v-if="user.value">
          <div class="account-user__ico">
            <i :class="user.ico"></i>
          </div>
          <p class="account-user__value">{{ user.value }}</p>
          <div class="account-user__detail" v-if="monitorsOnLine.length && user.isMonitors && getRole === 'model'">
            <p class="account-user__value" @click="showMonitor">
              <i class="fa fa-eye account-user__eye"></i>
              Monitores
            </p>
          </div>
        </div>
      </div>
      <div class="account-user__container__monitors" v-if="showMonitors">
        <p class="account-user__monitor">Monitores</p>
        <div class="account-user__list">
          <div class="account-user__list-monitors" v-for="(monitor, id) in monitorsOnLine" :key="id">
            <li class="account-user__item">{{ monitor.user }}</li>
            <div class="account-user__line"></div>
          </div>
        </div>
        <button class="account-user__btn" @click="showMonitor">Aceptar</button>
      </div>
      <div class="account-user__row-wrapper--button" v-if="getRole == 'coordinator' || getRole == 'superadmin' || getRole == 'monitor'">
        <button class="account-user__button-edit" type="submit" @click="isShowEdit">
          Actualizar perfil
        </button>
      </div>
    </div>

    <div class="account-user__container" v-else>
      <h3 class="account-user__title account-user__title--admin">INFORMACIÓN DE CUENTA</h3>
      <form class="account-user__form" id="account-user-form" @submit.prevent="onSubmit" enctype="multipart/form-data">
        <label v-if="getRole !== 'monitor' && valid" class="account-user__form-label" for="username">Nombre de usuario</label>
        <input v-if="getRole !== 'monitor' && valid" class="account-user__form-input" type="text" name="user" ref="name" v-model="inputs.user" @blur="formatString('user', 'Usuario')" />
        <div ref="user" class="account-user-error"></div>
        <label v-if="getRole !== 'monitor' && valid" class="account-user__form-label" for="email">Correo electrónico</label>
        <input class="account-user__form-input" v-if="getRole !== 'monitor' && valid" type="text" name="email" ref="email" v-model="inputs.email" @blur="formatEmail('email')" />
        <div ref="email" class="account-user-error"></div>
        <label v-if="getRole !== 'monitor' && valid" class="account-user__form-label" for="name">Nombre</label>
        <input class="account-user__form-input" type="text" name="name" v-if="getRole !== 'monitor' && valid" v-model="inputs.name" ref="name" @blur="formatString('name', 'Nombre')" />
        <div ref="name" class="account-user-error"></div>
        <label v-if="getRole !== 'monitor' && valid" class="account-user__form-label" for="lastName">Apellidos</label>
        <input
          class="account-user__form-input"
          type="text"
          name="lastname"
          v-model="inputs.lastname"
          ref="lastname"
          @blur="formatString('lastname', 'Apellido')"
          v-if="getRole !== 'monitor' && valid"
        />
        <div ref="lastname" class="account-user-error"></div>
        <label v-if="getRole !== 'monitor' && valid" class="account-user__form-label" for="phone">Celular</label>
        <input class="account-user__form-input" type="text" name="phone" v-model="inputs.phone" ref="phone" v-if="getRole !== 'monitor' && valid" @blur="formatNumber('phone', 'Celular')" />
        <div ref="phone" class="account-user-error"></div>
        <label class="account-user__form-label" for="city">Ciudad</label>
        <select-input @selected="changeCity" class="account-user__form-select" name="city" :options="getCityOptions" ref="select_city" />
        <label class="account-user__form-label" for="city">sede</label>
        <select-input class="account-user__form-select" name="office" v-if="getRole == 'monitor'" :options="getOfficesOptions" ref="select_office" />
        <label class="account-user__form-label" v-if="getRole == 'superadmin'">Imagen</label>
        <b-form-file class="account-user__form-file" v-if="getRole == 'superadmin'" :placeholder="placeImage" name="picture"></b-form-file>

        <div class="account-user__form-buttons">
          <input class="account-user__form-submit account-user__form-submit--save" type="submit" value="Actualizar" />
          <input class="account-user__form-submit account-user__form-submit--cancel" type="submit" value="Cancelar" @click="isShow = true" />
        </div>
      </form>
    </div>
    <div class="account-user__model-info" v-if="getRole === 'monitor' || user.isSpecialFunctions">
      <h6 class="account-user__subtitle">Modelos oficiales</h6>
      <p class="account-user__model-username" v-for="(model, id) in officialModels" :key="id + 'model'">
        <span class="account-user__avatar-container twoColumns">
          <div class="account-user__block left">
            <span class="account-user__state item__state--info" :state="getState(model)"></span>
            <div class="name">
              <div class="name__container">
                {{ model.artisticName }}
              </div>
              <div class="account-user__model-city" v-if="model.role === 'model'">{{ model.city }} - {{ model.office }} - {{ model.room }}</div>
            </div>
          </div>
          <div class="account-user__block right">
            <button class="account-user__soltar" v-if="model.isStreaming && model.monitorIdTemp === user._id" @click="finishStreaming(model.email, model._id)">
              Desconexión
            </button>
          </div>
        </span>
      </p>
      <h6 class="account-user__subtitle">Modelos temporales</h6>
      <p class="account-user__model-username" v-for="(model, id) in temporalModels" :key="id + 'modelTemp'">
        <span class="account-user__avatar-container twoColumns">
          <div class="account-user__block left">
            <span class="account-user__state item__state--info" :state="getState(model)"></span>
            <div class="name">
              <div class="name__container">
                {{ model.artisticName }}
              </div>
              <div class="account-user__model-city" v-if="model.role === 'model'">{{ model.city }} - {{ model.office }} - {{ model.room }}</div>
            </div>
          </div>
          <div class="account-user__block right">
            <span class="account-user__complementIco"></span>
            <button class="account-user__soltar" v-if="model.isStreaming && model.monitorIdTemp === user._id" @click="finishStreaming(model.email, model._id)">
              Desconexión
            </button>
          </div>
        </span>
      </p>
    </div>
    <div class="account-user__" v-if="isCoorSpecial($userData) || $userData.role === 'superadmin' || $userData.role === 'monitor'">
      <UserCard />
    </div>

    <cardStopWatch v-if="getRole === 'model'" />
  </section>
</template>
<script>
  import { mapActions, mapState, mapGetters } from "vuex";
  import roles from "@/model/Role.js";
  import { autoLogin } from "@/js/applications";
  import axios from "@/api/axios";
  import selectInput from "@/components/widgets/SelectInput";
  import Bar from "@/components/goals/Bar.vue";
  import Award from "@/components/goals/awards/Award.vue";
  import { socket } from "@/socket";
  import router from "../../router";
  import Swal from "sweetalert2";
  import { formatNumber, formatString, formatEmail } from "@/js/validation.js";
  import cardStopWatch from "@/components/chronometer/CardStopWatch.vue";
  import BreakModel from "../../components/art/BreakModel.vue";
  import service from "@/js/services.js";
  import alerts from "@/js/swalAlerts";
  import Badge from "@/components/goals/Badge.vue";
  import UserCard from "@/components/cards/UserCard.vue";
  import DropDownCollapse from "@/components/DropDownCollapse/DropdownCollapse.vue";

  export default {
    components: { selectInput, cardStopWatch, BreakModel, Bar, Award, Badge, UserCard, DropDownCollapse },
    data() {
      return {
        roles,
        default: { value: "", text: "Seleccione..." },
        monitorAssign: undefined,
        isShow: true,
        city: null,
        placeImage: "",
        loadingGoals: true,
        inputs: {
          user: "",
          email: "",
          name: "",
          lastname: "",
          phone: "",
          city: "",
        },
        error: {
          user: "",
          email: "",
          name: "",
          lastname: "",
          phone: "",
        },
        isValidForm: {},
        isModalActive: false,
        modelAwardsKey: 0,
        showMonitors: false,
        showMask: false,
        valid: true,
        backupButtons: [],
        showDropdown: false,
      };
    },
    computed: {
      ...mapGetters("offices", { getOffices: "getData" }),
      ...mapState("user", {
        user: (state) => state.profile,
      }),
      ...mapGetters("user", {
        getId: "getId",
        getRole: "getRole",
        getEmail: "getEmail",
        getProfile: "getProfile",
      }),
      ...mapGetters("cities", {
        getCities: "getData",
        getErrorCities: "getError",
      }),
      monitorsOnLine() {
        const monitors = this.$store.getters["connectedUsers/getMonitors"];
        const listMonitors = monitors.filter((monitor) => monitor.office === this.$userData.office);
        return listMonitors;
      },
      officialModels() {
        return this.$store.getters["connectedUsers/getOfficialModels"];
      },
      temporalModels() {
        return this.$store.getters["connectedUsers/getTemporalModels"];
      },
      assignedMonitor() {
        return this.$store.getters["connectedUsers/monitorAssigned"](null);
      },
      hasAwards() {
        return this.modelGoals.awards.some((award) => award.value > 0) || this.modelGoals.badges.length;
      },
      getUser: function() {
        return [
          {
            ico: "fas fa-user",
            value: this.getRole === "model" ? this.user.artisticName : this.user.user,
          },
          { ico: "fas fa-envelope-square", value: this.getRole !== "model" ? this.user.email : "" },
          {
            ico: "fas fa-id-card",
            value: this.getRole === "model" ? "" : `${this.user.name}  ${this.user.lastname}`,
          },
          { ico: "fas fa-phone", value: this.getRole === "model" ? "" : this.user.phone },
          { ico: "fas fa-city", value: this.user.city },
          {
            ico: "fas fa-building",
            value: this.getRole === "superadmin " || this.getRole === "coordinator" ? "" : this.user.office,
            isMonitors: this.getRole === "model",
          },
          { ico: "fas fa-hotel", value: this.user.room },
        ];
      },
      getOfficesOptions() {
        if (this.city) {
          return [...this.getOffices.filter((office) => office.cityName === this.city).map((office) => ({ value: office.officeName, text: office.officeName }))];
        }
        return [this.default];
      },
      getCityOptions: function() {
        let _cities = !!this.getCities() && this.getCities() instanceof Array ? this.getCities() : [];
        let cities = _cities.map((city) => {
          return { value: city.cityName, text: city.cityName };
        });
        cities.unshift({ value: "", text: "Seleccione..." });
        return cities;
      },
      modelGoals() {
        this.modelAwardsKey++;
        return this.$store.state.goals.modelGoals[0];
      },
      runGoals() {
        if (this.$userData.role === "model") this.initModelGoals();
      },
      myUser() {
        const models = this.$store.getters["connectedUsers/getModelsInRoom"] || [];
        return models.find(({ _id }) => this.$userData._id === _id) || {};
      },
      showButtonToBroadcast() {
        return !this.myUser?.roomTemp?._id && !this.assignedMonitor && this.user.role === "model" && this.$userData.room === "Satelite";
      },
      isPresencialModel() {
        return this.$userData.role === "model" && this.$userData.city !== "Global";
      },
      msgVerifyRoom() {
        return this.$store.state.user.msgVerifyRoom;
      },
      userCanUseBot() {
        const { role, city } = this.myUser;
        return process.env.VUE_APP_MODE !== "prod" || (role === "model" && city === "Medellín");
      },
    },
    methods: {
      ...mapActions("cities", { fetchCity: "fetch" }),
      ...mapActions("platforms", ["authJasminCam", "authStreamate", "authModelcenter"]),
      changeCity(city) {
        this.city = city;
        this.getOfficesOptions;
      },
      finishStreaming(emailModel, modelId) {
        alerts.endTransmission((decision) => {
          if (decision === "break") {
            socket.emit("finishStreaming", emailModel);
          }
          if (decision === "endTransmission") {
            service.updateStateReview(modelId);
            socket.emit("finishStreamingAtAll", modelId);
          }
        });
      },
      isShowEdit() {
        this.inputs = {
          user: !!this.user ? this.user.user : "",
          email: !!this.user ? this.user.email : "",
          name: !!this.user ? this.user.name : "",
          lastname: !!this.user ? this.user.lastname : "",
          phone: !!this.user ? this.user.phone : "",
          city: !!this.user ? this.user.city : "",
        };
        this.city = !!this.user ? this.user.city : "";

        if (!!this.user && this.user.picture) this.placeImage = this.user.picture.split("/").pop();
        this.isShow = false;
        setTimeout(() => {
          const index = this.getCityOptions.findIndex((item) => item.value === this.user.city);
          const indexz = this.getOfficesOptions.findIndex((item) => item.value === this.user.office);
          this.$refs.select_city.selectedOption(index !== -1 ? index : 0);
          if (this.getRole == "monitor") this.$refs.select_office.selectedOption(indexz !== -1 ? indexz : 0);
        }, 200);
      },
      checkedForm(form) {
        for (const item of form) {
          if (!item) {
            return false;
          }
        }
        return true;
      },
      formatNumber(ref, info) {
        const refs = this.$refs[ref];
        this.isValidForm[info] = formatNumber(refs, info, event.srcElement);
      },
      formatEmail(ref) {
        const refs = this.$refs[ref];
        this.isValidForm.email = formatEmail(refs, "", event.srcElement);
      },
      formatString(ref, info) {
        const refs = this.$refs[ref];
        this.isValidForm[info] = formatString(refs, info, event.srcElement);
      },
      dataSending: function() {
        Swal.fire({
          title: "Actualizando",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
      },
      setDataStreaming(app) {
        this.notificationStreaming(app);
        localStorage.setItem("startStreaming", new Date());
      },
      async openByOffice(platform) {
        try {
          this.showDropdown = true;
          const dicPlatform = {
            modelcenter: {
              key: "LiveJasmin",
              botMethod: this.authModelcenter,
              currentParams: ["/downloadfilevbsweb", "jasminweb", "LiveJasmin"],
            },
            jasmin: {
              key: "LiveJasmin",
              botMethod: this.authJasminCam,
              currentParams: ["/downloadfilevbs?version=5", "desktop", "LiveJasmin"],
            },
            streamate: {
              key: "Streamate",
              botMethod: this.authStreamate,
              currentParams: ["/downloadfilevbsstreamate", "streamate", "Streamate"],
            },
          };

          if (!dicPlatform[platform]) {
            throw new Error("No se encuentran los datos para iniciar la plataforma");
          }

          if (this.userCanUseBot) {
            if (this.verifyIsBreak()) {
              return;
            }

            await dicPlatform[platform].botMethod(this.getProfile);
            this.setDataStreaming(dicPlatform[platform].key);

            return;
          }

          this.openAutoLogin(...dicPlatform[platform].currentParams);
        } catch (error) {
          console.log("openByOffice:", error);
        }
      },
      openAutoLogin: async function(endpoint, platform, app) {
        this.showDropdown = true;
        const item = localStorage.getItem("startStreaming");
        const diff = new Date().getTime() - new Date(item).getTime();
        const hours = Math.floor(diff / (1000 * 60));
        if (item && endpoint !== "/downloadfilevbsweb") {
          const { isConfirmed } = await this.$alerts.dataSendConfirm({
            title: "¿Desea continuar con la transmisión?",
            message: `Ya se ha iniciado la transmisión Hace ${hours} minutos, si continua se cerrara la ventana de streaming  actual.`,
            confirmButtonText: "Continuar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
          });
          if (!isConfirmed) return;
        }
        if (this.verifyIsBreak()) {
          return;
        }
        autoLogin(endpoint, platform, null, this.$store);
        this.notificationStreaming(app);
        if (endpoint !== "/downloadfilevbsweb") localStorage.setItem("startStreaming", new Date());
      },
      verifyIsBreak() {
        if (this.getProfile.isBreak) {
          this.$refs.modalBreak.$el.style.display = "block";
        }
        return this.getProfile.isBreak;
      },
      validatePermissions(rules) {
        return rules.filter((rule) => rule.tag === this.user.role).length > 0;
      },
      getStreaming(streaming) {
        if (streaming === "NA" || streaming === null) return false;
        return !!streaming;
      },
      async assignMonitor() {
        return;
      },
      citySelected: async function(value) {
        if (value !== "") {
          this.city = value;
        } else {
          this.city = null;
        }
      },
      closeSession(email) {
        socket.emit("logout", email);
        this.$store.dispatch("user/logoutMethod");
        this.$store.state.user.token = null;
        this.$store.state.user.accountant = 0;
        router.push({ path: "/login" }).catch(() => {});
      },
      async onSubmit() {
        this.valid = false;
        const _data = new FormData(document.getElementById("account-user-form"));
        _data.append("documentId", this.user.documentId);
        const isValidForm = Object.values(this.isValidForm);
        if (!this.checkedForm(isValidForm)) {
          Swal.fire({
            text: "Se encontraron errores, por favor verificar el formulario.",
            icon: "error",
            confirmButtonColor: "#bd0909",
          });
          return null;
        }
        this.dataSending();
        const res = await axios.put(`admins/${this.user._id}?flags=true`, _data);
        Swal.close();
        if (res.data.error) {
          Swal.fire({
            title: "Error",
            text: "Usuario no Actualizado.",
            icon: "error",
            confirmButtonColor: "#bd0909",
          });
        } else {
          if (this.user.user !== res.data.data.user || this.user.email !== res.data.data.email) {
            await Swal.fire({
              text: "Actualizado Ingresa Nuevamente",
              icon: "success",
              confirmButtonColor: "#bd0909",
            });
            this.closeSession(res.data.data.email);
          } else {
            Swal.fire({
              text: "Actualizado",
              icon: "success",
              confirmButtonColor: "#bd0909",
            });
            this.$store.state.user.profile = res.data.data;
            this.$root.$emit("ProfileUpdate", res.data.data);
            this.isShow = true;
            if (this.user.picture) this.placeImage = this.user.picture.split("/").pop();
          }
        }
        this.valid = true;
      },
      notEmpty: function(key) {
        const input = document.querySelector(`input[name=${key}]`);
        if (this.inputs[key].length > 0) {
          this.error[key] = "";
          if (input.classList.contains("is-error")) {
            input.classList.remove("is-error");
          }
        } else {
          this.error[key] = "No puede ser vacío";
          if (!input.classList.contains("is-error")) {
            input.classList.add("is-error");
          }
        }
      },
      getState: function(model) {
        if (model.isOnline && model.monitorId === this.getId() && model.monitorIdTemp !== "" && model.monitorIdTemp !== model.monitorId) {
          return "monitorTemp";
        }
        if (model.isOnline && model.monitorIdTemp !== model.monitorId && model.monitorIdTemp === this.getId() && !model.isBreak) {
          return "monitorTemp";
        }
        if (model.isOnline && model.isActive) {
          return "online";
        }
        if (model.isOnline && !model.isActive) {
          return "notActive";
        }
        if (model.isOnline && model.monitorIdTemp !== this.getId()) {
          return "monitorTemp";
        }
        if (!model.isOnline) {
          return "notOnline";
        }
        return "";
      },
      notificationStreaming(app) {
        socket.emit("notifyStreaming", { modelEmail: this.getProfile.email, app: app });
      },
      async initModelGoals() {
        this.loadingGoals = true;
        await this.$store.dispatch("goals/initGoals");
        const { data } = await this.$axios(`/model/goals/${this.$userData._id}`);
        this.$store.state.goals.modelGoals = [data];
        this.loadingGoals = false;
      },
      showMonitor() {
        this.showMonitors = !this.showMonitors;
        this.showMask = !this.showMask;
      },
      async wantToBroadcast() {
        try {
          const { data } = await this.$axios.post(`/operation/wantToBroadcast?setRoomTemp=true`);
          const { error, success } = data;

          if (error) {
            throw new Error(error.message);
          }
        } catch (error) {
          this.$alerts.dataSendError({ message: error.message });
        }
      },
      async verifyRoom() {
        await this.$store.dispatch("user/validateRoomConnection");
      },
      openBack() {
        this.openAutoLogin(`/downloadfilevbs?version=5`, "desktop", "LiveJasmin");
      },
      setUpBackupButtons() {
        if (this.$userData.jasminUser) {
          this.backupButtons.push(
            {
              name: "Estadísticas",
              style: "jasmin",
              action: () => this.openAutoLogin("/downloadfilevbsweb", "jasminweb", "LiveJasmin"),
            },
            {
              name: "Live Jasmin",
              style: "jasmin",
              action: () => this.openAutoLogin(`/downloadfilevbs?version=5`, "desktop", "LiveJasmin"),
            }
          );
        }

        if (this.$userData.streamateUser) {
          this.backupButtons.push({
            name: "Streamate",
            style: "streamate",
            action: () => this.openAutoLogin("/downloadfilevbsstreamate", "streamate", "Streamate"),
          });
        }
      },
    },
    async beforeMount() {
      if (this.isPresencialModel) {
        await this.verifyRoom();
      }
    },

    mounted() {
      this.setUpBackupButtons();
    },
  };
</script>
<style lang="scss">
  .swal2-loader {
    border-color: $lipstick transparent $lipstick;
  }
  .account-user {
    position: relative;
    &__backupButtons {
      position: absolute;
      right: 0;
      top: 0;
    }
    max-width: 820px;
    &__monitorAvatar {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
    &__mask {
      background: gray;
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 1;
      top: 0;
      left: 0;
      opacity: 0.5;
    }
    &__msgRoom {
      margin-top: 10px;
      @include Flex(row);
      gap: 10px;
    }
    &__iconMsgRoom {
      cursor: pointer;
    }
    &__line {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      width: 93%;
      height: 2px;
    }
    &__detail {
      padding: 0 10px;
      cursor: pointer;
    }
    &__item {
      text-transform: capitalize;
    }
    &__eye {
      margin-left: 30px;
      color: $primary-color;
    }

    &__monitor {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
      text-transform: capitalize;
    }
    &__btn {
      background: $primary-color;
      border: solid 1px $primary-color;
      border-radius: 4px;
      color: #fff;
      width: 102px;
      height: 30px;
      margin-top: 5px;
      &:focus {
        outline: $primary-color;
      }
    }
    &__list {
      padding-left: 5px;
      height: 170px;
      overflow: auto;
    }
    &__list-monitors {
      text-align: start;
      list-style: none;
      margin-bottom: 10px;
    }
    &__soltar {
      margin-bottom: $mpadding/2;
      text-align: end;
      align-self: flex-end;
      font-size: 0.6em;
      border: none;
      @include ButtonRed();
      &:hover {
        opacity: $opacity-button;
      }
    }
    &-error {
      font-size: 75%;
      color: $primary_color;
      font-weight: bold;
      width: 90%;
      margin: 2px auto;
    }
    &__buttons-model {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $space-6;
    }
    &__mainButtons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__button-model {
      outline: none;
      text-decoration: none;
      border-radius: $border-radius-bootstrap;
      color: $white;
      border: none;
      background-color: $lipstick;
      margin-right: $space-16;
      padding: $mpadding/2;
      outline-style: none;
      &:hover {
        opacity: $opacity-button;
      }
      &--streamate {
        background-color: $matisse;
      }
    }
    &__button {
      max-width: 150px;
      margin-left: 15px;
      border: none;
      background-color: $primary;
      color: white;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 5px 8px;
      &:hover {
        background-color: $lipstick;
        opacity: $opacity-button;
      }
      &-container {
        flex-grow: 3;
        text-align: right;
      }
    }
    &__container {
      padding-bottom: 30px;
      box-shadow: $dshadow;
      border-radius: $mradius;
      background-color: $white;
      &__monitors {
        position: absolute;
        padding: 5px 10px;
        width: 240px;
        height: 260px;
        border-radius: 4px;
        top: 76%;
        left: 10%;
        background: #fff;
        text-align: center;
        box-shadow: 0 2px 5px #d8d8d8;
        z-index: 10;
      }
    }
    &__title {
      padding: $mpadding;
      font-size: $title-mobile;
      color: $primary;
      &--admin {
        padding-top: $mpadding * 2;
        background-color: $white;
        color: $primary;
      }
    }
    &__subtitle {
      padding: $mpadding;
      padding-left: 45px;
      font-size: $subtitle-mobile;
    }
    &__row-wrapper--button {
      text-align: center;
    }
    &__row {
      @include Row(flex-start);
      margin: 0 40px;
      padding: 5px 0;
      border-bottom: 1px solid $alto;
      word-break: break-all;
      &:first-child {
        margin-top: $mpadding;
      }
    }
    &__ico {
      @include Row();
      width: 20px;
      height: 20px;
      padding: 10px;
      margin-right: 15px;
      > i {
        color: $primary;
        font-size: 18px;
      }
    }
    &__button-edit {
      @include ButtonStyle();
      margin: 20px auto;
      background-color: $lipstick;
      padding-right: $mpadding;
      padding-left: $mpadding;
    }
    &__form {
      @include Column(center, flex-start);
      &-error {
        width: 90%;
        margin: -5px auto 0 auto;
        position: relative;
        font-size: 75%;
        font-weight: bold;
        text-align: left;
        color: $primary_color;
      }
      &-label {
        width: 90%;
        margin: 5px auto;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        color: $gray-emperor;
      }
      &-file {
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        color: $gray-emperor;

        .custom-file-label {
          border: none;
          cursor: pointer;
          background-color: $chicago;
          color: $white;
          width: 90%;
          margin: 5px auto;
        }
        .custom-file-input ~ .custom-file-label[data-browse]::after {
          display: none;
        }
      }
      &-input {
        width: 90%;
        margin: 5px auto;
        @include Input();
        &.is-error {
          border: 1px solid $lipstick;
        }
      }
      &-submit {
        @include ButtonStyle();
        width: 110px;
        background-color: $lipstick;
        margin: 10px 0;
        &--save {
          margin-right: $mpadding;
        }
        &--cancel {
          background-color: $gray-emperor;
        }
      }
      &-select {
        width: 90%;
        margin: 10px auto;
        @include Input();
      }
      &-buttons {
        margin: 25px auto 5px;
      }
    }
    &__model-info {
      margin-top: $mpadding;
      padding-bottom: 30px;
      box-shadow: $dshadow;
      border-radius: $mradius;
      background-color: $white;
    }
    &__model-city {
      margin-top: -5px;
      font-size: 0.7rem;
    }
    &__avatar-container {
      @include Row(flex-start);
      position: relative;
      margin: 0 40px $mpadding/2 40px;
      .name {
        margin-left: 10px;
        word-break: break-word;
      }
      &.twoColumns {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
      }
    }
    &__block {
      @include Row(flex-start);
      position: relative;
      &.right {
        gap: 10px;
        align-self: center;
      }
    }

    &__complementIco {
      width: 16px;
    }
    &__state {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      border-radius: 100%;
      background-color: $gray-emperor;
      &--info {
        margin-right: 5px;
      }
    }
    @media screen and (min-width: $pocket_width) {
      &__avatar-container {
        &.twoColumns {
          flex-direction: row;
        }
      }
      &__block {
        &.left {
          width: 250px;
        }
      }
    }
    @media screen and (min-width: $tablet_width) {
      &__title {
        font-size: $title-desktop;
        padding-left: 40px;
      }
      &__subtitle {
        font-size: $subtitle-desktop;
      }
      &__buttons-model {
        min-width: 500px;
      }
      &__container {
        min-width: 500px;
      }
    }
    @media (min-width: $desktop_width) {
      margin-top: 40px;
      width: 100%;
      &__form {
        &-submit {
          width: 150px;
        }
      }
    }
  }
  .streaming-container {
    display: flex;
  }

  .account-container {
    display: flex;
  }
  @media (min-width: $tablet_width) {
    .account-user {
      &__soltar {
        margin-bottom: 0;
      }
    }
    .name__container {
      grid-template-columns: 300px 1fr;
    }
  }

  .goalsFieldset {
    border: 2px solid $primary_color;
    border-radius: 10px;
    background: none;
    margin-top: 30px;
    padding: 10px 20px 20px;

    &-awards {
      gap: 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 10px 0;
      svg {
        width: 120px;
        height: 120px;
        text {
          font-family: $sec_font;
        }
      }
    }

    &-badge {
      cursor: pointer;
      .badgeItem-image {
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    &-bar,
    &-card {
      background: white;
      box-shadow: $dshadow;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 15px;

      .goalBarContainer {
        flex-direction: column;
        align-items: flex-start;
      }

      .modelsGoalsCard-name {
        margin-left: 10px;
        color: $primary_color;
        @media (min-width: $tablet_width) {
          margin-left: 20px;
        }
      }
    }

    legend {
      margin-left: 20px;
      font-size: 32px;
      font-family: $sec_font;
      color: $primary_color;
      font-weight: bold;
      padding: 0 5px;
      width: auto;
      display: inline-block;
    }
  }

  @media screen and (min-width: $tv_width) {
    .progressBar-container {
      width: 100%;
    }
  }
</style>
