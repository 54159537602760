var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`dropdown dropdown--${_vm.type}`},[_c('div',{class:`dropdown__header dropdown__header--${_vm.type}`,on:{"click":_vm.toggleDropdown}},[_c('h3',{staticClass:"dropdown__title"},[_vm._v(_vm._s(_vm.title))]),_c('button',{staticClass:"dropdown__collapseButton",attrs:{"type":"button"}},[_c('iconic',{attrs:{"name":_vm.isOpen ? 'chevronUp' : 'chevronDown'}})],1)]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isOpen)?_c('div',{class:[
        'dropdown__content',
        `dropdown__content--${_vm.type}`,
        {
          'dropdown__content--open': _vm.isOpen,
          [`dropdown__content--${_vm.type}--open`]: _vm.isOpen && _vm.type,
        },
      ]},[_c('ul',{staticClass:"dropdown__list"},_vm._l((_vm.items),function(option,idx){return _c('li',{key:idx + option.name,class:`dropdown__listItem dropdown__listItem--${_vm.type} dropdown__listItem--${option.style}`,on:{"click":function($event){return _vm.handleAction(option)}}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }