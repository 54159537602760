<template>
  <section class="break" id="breakModal">
    <div class="break__mask"></div>
    <div class="break__container">
      <h1>Receso</h1>
      <p>Para continuar debe ingresar el tipo de receso que tomo</p>
      <b-alert class="break__alert" v-if="type" show variant="danger">Por favor ingrese un tipo de receso</b-alert>
      <div class="break__checkBox" id="input-role" lable="Role" :key="key">
        <div class="break__option" v-for="type in typeBreak" :key="`role-${type.name}`">
          <input class="mycheck circle" type="radio" name="break" :value="type.name" />
          <span class="break__describe">{{ type.name }}</span>
        </div>
      </div>
      <div class="break__menu">
        <button class="break__btn" @click="sendTime">Continuar</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      key: 0,
      type: false,
      typeBreak: [{ name: "Descanso" }, { name: "Baño(s)" }, { name: "Alimentación" }],
    };
  },
  methods: {
    sendTime() {
      this.checkTypeBreak();
      if (this.type) {
        return null;
      }
      this.$root.$emit("sendTime");
      this.key++;
    },
    checkTypeBreak() {
      const radios = document.getElementsByName("break");
      for (const radio of radios) {
        if (radio.checked) {
          this.type = false;
          return null;
        }
      }
      this.type = true;
    },
  },
};
</script>

<style lang="scss">
.break {
  &__mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.144);
    z-index: 998;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 999;
    text-align: center;
    width: calc(100vw - 32px);
    max-width: 600px;
    padding: $mpadding;
    margin: 0 auto;
    background-color: $white;
    border-radius: $mradius;
    box-shadow: $dshadow;
    margin-top: -150px;
  }
  &__checkBox {
    max-width: 170px;
    display: flex;
    flex-direction: column;
    margin: $mpadding 0;
    border: solid 1px $alto;
    border-radius: $mradius;
    padding: $mpadding;
  }
  &__option {
    display: flex;
    align-items: center;
    margin-bottom: $mpadding/2;
  }
  &__describe {
    margin-left: $mpadding/2;
  }
  &__menu {
    margin-top: $mpadding;
  }
  &__btn {
    @include ButtonStyle();
    background-color: $lipstick;
    padding: $mpadding/2;
    &--cancel {
      margin-left: $mpadding/2;
      background-color: $tundora;
    }
  }
  &__alert {
    margin-top: $mpadding;
    margin-bottom: 0;
  }
}
@media (min-width: $modal_width) {
  .break {
    &__container {
      margin-left: calc(50vw - 300px);
    }
  }
}
@media (min-width: $desktop_width) {
  .break {
    &__container {
      margin-left: calc(50% - 550px);
      margin-top: 0;
    }
  }
}
.hidden {
  display: none;
}
</style>
